<template>
  <div class="container">
    <h1>ආයුබෝවන්!<br />DevFest SL 2024 Check-In</h1><br><br>
    <form @submit.prevent="handleSubmit" class="form">
      <v-row align="center" justify="start" class="form-group">
        <v-col cols="12" sm="6" md="4">
          <div class="field">

            <label>Ticket QR:</label>
            <input type="text" v-model="name" required />
          </div>
          <br />
          <div class="field">

            <label v-if="exceptionTicket">New NIC:</label>
            <input v-if="exceptionTicket" type="text" v-model="exceptionTicketNic" />
          </div>

          <br />
          <div class="field">

            <label v-if="exceptionTicket">New Contact:</label>
            <input v-if="exceptionTicket" type="text" v-model="exceptionTicketContact" />
          </div>

        </v-col>
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <button type="button" class="qr-button .me-3" @click="toggleQrScanner">Scan QR</button>
          <v-spacer></v-spacer>
          <button type="submit" class="submit-button button1">Mark Attendance</button>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <button type="button" class="button2" @click="resetAll">Reset</button>
          <v-spacer></v-spacer>
          <button v-if="!exceptionTicket" type="button" class="button3" @click="exceptionTicket = true">Exception
            Ticket</button>
        </v-col>
      </v-row>
    </form>

    <div id="qr-reader" v-if="showQrScanner"></div>
    <div id="qr-reader-results"></div>
    <div v-if="showRec" class="record-display">
      <v-row>
        <v-col cols="6"><strong>Ticket ID:</strong></v-col>
        <v-col cols="6">{{ this.nameBack }}</v-col>


        <v-col cols="6"><strong>Name:</strong></v-col>
        <v-col cols="6">{{ record.Name }}</v-col>

        <v-col cols="6"><strong>Email:</strong></v-col>
        <v-col cols="6">{{ record.Email }}</v-col>

        <v-col cols="6"><strong>Contact:</strong></v-col>
        <v-col cols="6">{{ record.Contact }}</v-col>

        <v-col cols="6"><strong>NIC:</strong></v-col>
        <v-col cols="6"><b>{{ record.NIC }}</b></v-col>

        <v-col cols="6"><strong>Gender:</strong></v-col>
        <v-col cols="6">{{ record.Gender }}</v-col>

        <v-col cols="6"><strong>Meal Preference:</strong></v-col>
        <v-col cols="6">
          <v-chip v-if="record.Meal.startsWith('Veg')" color="green">{{ record.Meal }}</v-chip>
          <v-chip v-else color="red">{{ record.Meal }}</v-chip>
        </v-col>

        <v-col cols="6"><strong>T-shirt Size:</strong></v-col>
        <v-col cols="6"><v-chip color="yellow">{{ record.TSize }}</v-chip></v-col>
      </v-row>
    </div>

    <div v-if="result" class="result">
      <h2>Submitted</h2>
    </div>

    <v-snackbar color="red" v-model="snackbar" timeout=2000 centered col>
      Already Registerd at {{ alreadyRegTime }}

      <template v-slot:actions>
        <v-btn variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="green" v-model="snackbar_succ" timeout=1000 centered col>
      Done
      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar_succ = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="blue" v-model="snackbar_err" timeout=5000 centered col>
      ERROR !!! Please Retry
      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar_err = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import db from "../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { Html5Qrcode } from "html5-qrcode";
import recordsData from '../assets/records.json';

export default {
  data() {
    // Get the current time
    let currentTime = new Date();
    // Format the result in a nice way (e.g., HH:MM:SS)
    let reducedTime = currentTime.toLocaleTimeString();

    return {
      exceptionTicket: false,
      exceptionTicketNic: '',
      exceptionTicketContact: '',
      date: reducedTime,
      name: "",
      nameBack: "",
      alreadyRegTime: '',
      showQrScanner: false,
      result: null,
      snackbar: false,
      snackbar_succ: false,
      snackbar_err: false,
      qrCodeScanner: null,
      foundTicket: null,
      records: recordsData,
      record: {
        Ticket: null,
        Name: null,
        Email: null,
        Contact: null,
        NIC: null,
        Gender: null,
        Meal: null,
        TSize: null
      },
      showRec: false
    };
  },
  watch: {
    name(newValue) {
      if (newValue.length > 7) {
        this.record = this.searchTicket(this.name);
        this.showRec = true;
      }
    },
  },
  methods: {
    async handleSubmit() {
      this.exceptionTicket = false;
      this.record = this.searchTicket(this.name);
      if (this.record === null) {
        this.snackbar_err = true;
        return
      }
      this.showRec = true;

      if (!this.name) {
        this.snackbar = true;
        return;
      }

      try {
        const documentRef = doc(db, "att", this.name);
        const docSnap = await getDoc(documentRef);
        if (docSnap.exists()) {
          this.alreadyRegTime = docSnap.data()['t'];
          this.nameBack = this.name
          this.snackbar = true;
          this.name = null;
        } else {
          await setDoc(documentRef, {
            t: this.date,
            n: this.exceptionTicketNic,
            c: this.exceptionTicketContact
          });
          this.snackbar_succ = true;
          this.nameBack = this.name
          this.name = null;
          this.exceptionTicketNic = ''
          this.exceptionTicketContact = ''
          this.showRec= false
        }
      } catch (e) {
        this.snackbar_err = true;
        console.error("Error adding document: ", e.message || e);
      }
    },
    searchTicket(searchTerm) {
      var toReturnVal = this.records[searchTerm]
      if (toReturnVal === null) {
        this.snackbar_err = true;
      }
      return toReturnVal || null;
    },

    toggleQrScanner() {

      this.showQrScanner = !this.showQrScanner;

      if (this.showQrScanner) {
        this.$nextTick(() => {
          this.initQrScanner();
        });
      } else {
        if (this.qrCodeScanner) {
          this.qrCodeScanner.stop();
          this.qrCodeScanner = null;

        }
      }
    },

    initQrScanner() {
      this.showRec = false
      if (!this.qrCodeScanner) {
        this.qrCodeScanner = new Html5Qrcode("qr-reader");
      }

      this.qrCodeScanner
        .start(
          { facingMode: "environment" }, // Use back camera for scanning
          {
            fps: 10, // Frames per second
            qrbox: { width: 250, height: 250 }, // QR scanner box size
          },
          (decodedText) => {
            // QR code detected
            this.name = decodedText;
            this.toggleQrScanner(); // Stop scanning
          },
          (errorMessage) => {
            console.warn(errorMessage); // Handle scan errors
          }
        )
        .catch((err) => {
          console.error("QR Code scanner failed to start:", err);
        });
    },
    resetAll() {
      this.name = ''
      this.exceptionTicket = false
      this.showRec= false
      if (this.showQrScanner) {
        this.showQrScanner = false
        try {
          this.qrCodeScanner.stop()
        } catch (e) {
          this.snackbar_err = true;
          console.error("Error adding document: ", e.message || e);
        }
      }

    },

  },

  beforeDestroy() {
    if (this.qrCodeScanner) {
      this.qrCodeScanner.stop();
    }
  },
};
</script>

<style scoped>
/* Add QR Button styling */
.qr-button {
  background-color: #ff9800;
  color: white;
  border: none;
  /* padding: 0.75rem; */
  border-radius: 4px;
  cursor: pointer;
  /* margin-left: 10px; */
}

.qr-button:hover {
  background-color: #e68900;
}

/* Adjust the QR Reader styling */
#qr-reader {
  margin: 1rem auto;
  width: 250px;
  height: 250px;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

body {
  display: flex;
  flex-direction: column;
}

header {
  background-color: #6a1b9a;
  color: white;
  padding: 1rem 0;
  text-align: center;
}

header nav a {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  font-weight: bold;
}

.container {
  flex: 1;
  width: 98%;
  max-width: 1000px;
  margin: 2rem auto;
  padding: 1.5rem;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1 {
  text-align: center;
  color: #6a1b9a;
}

form {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

label {
  margin-right: 0.5rem;
  font-weight: bold;
}

input[type="date"],
input[type="text"],
button {
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
  min-width: 150px;
}

button {
  color: white;
  border: none;
  cursor: pointer;
  flex: none;
  width: auto;
}

button:hover {
  background-color: #5a1282;
}

.result {
  margin-top: 1.5rem;
  padding: 1.5rem;
  border: 1px solid #ddd;
  background-color: #fafafa;
  border-radius: 8px;
}


.result h2 {
  margin-top: 0;
  color: #6a1b9a;
}

.result table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.result table,
.result th,
.result td {
  border: 1px solid #ddd;
  padding: 0.75rem;
  text-align: left;
}

.result th {
  background-color: #f5f5f5;
}

.response-text {
  background-color: #f4f4f4;
  padding: 1rem;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
  color: white;
}

@media (min-width: 600px) {
  .container {
    width: 80%;
  }

  form {
    justify-content: space-between;
  }
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.button2 {
  background-color: #b6b2b2;
}

/* Blue */
.button3 {
  background-color: #f5564b;
}

/* Green */
.button1 {

  background-color: #239a1b;
}

/* Red */

.field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

label {
  width: 100px;
  ;
}
</style>
