import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
    apiKey: "AIzaSyDnU8altRUaip-JFowiHBqACeTg8NTgUs8",
    authDomain: "devfest-24-checkin.firebaseapp.com",
    projectId: "devfest-24-checkin",
    storageBucket: "devfest-24-checkin.firebasestorage.app",
    messagingSenderId: "980876337338",
    appId: "1:980876337338:web:95dce6f28ee752468b2283"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export default db
