<template>
  <v-app>
    <v-app-bar
      app
      color="#6a1b9a"
      dark
    >
      <div class="d-flex align-center logo">
        <center>
          <v-img
          alt="Devfest Logo"
          class="align-center"
          contain
          src="https://devfest.gdgsrilanka.org/_next/static/media/DF24.1dca87ad.webp"
          transition="scale-transition"
          width="140"
        /></center>
        
      </div>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <Home/>
    </v-main>
  </v-app>
</template>

<script>
import Home from './components/home-page';

export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.logo{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
</style>
